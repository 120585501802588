<template>
  <div class="course-list scroll-box" @scroll="scrollBox($event)">
    <TopBar :type="2" :pageTitle="'开放式班级列表'" />
    <!-- <div class="select-bar">
      <div class="option classify" @click="categoryModal=!categoryModal">{{categoryText}}</div>
    </div> -->
    <ul class="list">
      <li
        class="info"
        v-for="item in list"
        :key="item.id"
      >
        <img class="img" :src="item.image" />
        <div class="content">
          <p class="name">{{ item.name }}</p>
          <p class="hour">{{ item.content }}</p>
          <p class="btn" @click="sightBtn(item.id)">报名</p>
          <!-- <p class="price" v-if="websiteData.is_course_pay==1"><span class="unit" v-if="item.price!='0.00'">￥</span>{{item.price!='0.00'?item.price:'免费'}}</p> -->
        </div>
      </li>
      <li class="no-data" v-if="list.length < 1">
        <img class="nodata-img" :src="require('@/assets/no-data.png')" />
        <p class="nodata-text">暂无课程</p>
      </li>
      <li class="more" :class="{ not: !more }">
        {{ more ? "查看更多" : "我是有底线的" }}
      </li>
    </ul>
    <transition name="slide-fade">
      <div class="classify-modal" v-show="categoryModal">
        <div class="content">
          <div class="menu-bg"></div>
          <ul class="menu">
            <li
              class="menu-list"
              :class="{ active: selectedMenu == '' }"
              @click="clickMenu('')"
            >
              全部
            </li>
            <li
              class="menu-list"
              :class="{ active: selectedMenu.id == item.id }"
              v-for="item in category"
              :key="item.id"
              @click="clickMenu(item)"
            >
              {{ item.name }}
            </li>
          </ul>
          <ul class="sub-menu">
            <li
              class="sub-menu-content"
              :class="{ active: categoryId == 0 }"
              @click="categoryListBtn(0)"
            >
              全部
            </li>
            <li
              class="sub-menu-content"
              :class="{ active: categoryId == item.id }"
              v-for="item in selectedMenu.child"
              :key="item.id"
              @click="categoryListBtn(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import TopBar from "@/m/common/topBar.vue";
import { mapMutations } from "vuex";

export default {
  name: "CourseList",
  data() {
    return {
      categoryText: "请选择分类",
      categoryModal: false,
      category: "", //分类
      selectedMenu: "", //选中分类
      categoryId: 0, //选中id
      text: "", //搜索框内容
      page: 1, //当前页
      pre_page: 10, //每页数量
      list: [], //数据
      more: true,
      websiteData: "",
    };
  },
  components: {
    TopBar,
  },
  mounted() {
    this.websiteData = JSON.parse(localStorage.getItem("websiteState"));

    this.categoryId = this.$route.params.id;
    this.categoryListBtn(); //课程列表
    this.getCategory(); //分类
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "ShowTipModal", "CloseTipModal"]), //登录弹窗

    //课程列表按钮
    categoryListBtn(item) {
      if (item && item.id) {
        this.categoryId = item.id;
        this.categoryText = item.name;
      } else if (item === 0) {
        this.categoryId = 0;
        this.categoryText = "全部";
      }

      this.page = 1;
      this.list = [];
      this.getCategoryList();
    },
    //获取课程列表
    getCategoryList() {
      this.$axios
        .post(`/v1/openClass/list`, {
          category_id:
            this.categoryId === 0 ? this.selectedMenu.id : this.categoryId,
          page: this.page,
          pre_page: this.pre_page,
        })
        .then((res) => {
          this.categoryModal = false;
          this.list.push(...res.data.data);

          if (res.data.total > this.list.length) {
            this.more = true;
          } else {
            this.more = false;
          }
        });
    },
    scrollBox(e) {
      if (
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <
          1 &&
        this.more
      ) {
        this.page++;
        this.getCategoryList();
      }
    },
    // 报名
    sightBtn(id) {
      let _this = this;
      _this.ShowTipModal({
        text: "确定报名该班级？", //提示弹窗内容
        showBtns: true,
        subBtnText: "报名",
        confirmFun: function () {
          _this.CloseTipModal();
          // _this.payNowBtn(_this.courseInfo.detail.id);
          this.$axios
            .post(
              `/v1/openClass/joinOpenClass`,
              { class_id: id },
              { useLog: true }
            )
            .then((res) => {
              _this.ShowTipModal({
                text: res.msg, //提示弹窗内容
              });
            });
        },
      });
    },
    //分类
    getCategory() {
      this.$axios.post(`/v1/textCourse/category`).then((res) => {
        this.category = res.data;
      });
    },
    //点击一级分类
    clickMenu(item) {
      this.selectedMenu = item;
    },
  },
};
</script>
<style scoped lang="scss">
.select-bar {
  padding: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 88px;
  z-index: 888;
  .option {
    height: 32px;
    line-height: 32px;
    font-size: 28px;
    display: flex;
    align-items: center;
    &::after {
      content: "";
      display: block;
      width: 32px;
      height: 32px;
      background: url(~@/assets/arrows-down-grey.png) center center no-repeat;
      background-size: auto 100%;
    }
  }
}
.list {
  .info {
    padding: 28px;
    overflow: hidden;
    .img {
      width: 308px;
      height: 200px;
      border-radius: 10px;
      float: left;
    }
    .content {
      width: 366px;
      height: 200px;
      float: right;
      position: relative;
      .name {
        font-size: 28px;
        // height: 80px;
        line-height: 40px;
        color: #333;
        margin-bottom: 10px;
      }
      p.btn {
        margin-top: 15px;
        padding: 20px;
        width: 70px;
        height: 26px;
        background: #9f1106;
        color: #fff;
        font-size: 12px;
        line-height: 26px;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
      }
      .hour {
        font-size: 24px;
        color: #999;
      }
      .price {
        font-size: 32px;
        color: #cd1b14;
        position: absolute;
        left: 0;
        bottom: 0;
        .unit {
          font-size: 24px;
        }
      }
    }
  }
}
.classify-modal {
  position: fixed;
  top: 180px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .content {
    width: 100%;
    height: 728px;
    background-color: #fff;
    position: relative;
  }
}
.menu-bg {
  position: absolute;
  width: 198px;
  top: 0;
  left: 0;
  bottom: 0;
  background: #f5f5f5;
  border-right: 2px solid #e5e5e5;
  z-index: 0;
}
.menu {
  position: absolute;
  width: 200px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;
}
.menu-list {
  width: 198px;
  height: 88px;
  line-height: 88px;
  font-size: 28px;
  text-align: center;
  &.active {
    width: 198px;
    background-color: #fff;
    border-right: 2px solid #fff;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 44px;
      height: 4px;
      background: #254ed4;
      border-radius: 1px;
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}
.sub-menu {
  box-sizing: border-box;
  position: absolute;
  width: 550px;
  height: 728px;
  top: 0;
  right: 0;
  z-index: 1;
  overflow-y: auto;
}
.sub-menu-content {
  padding: 24px 28px;
  font-size: 26px;
  &.active {
    color: #254ed4;
  }
}
::v-deep .nav .user {
  display: none;
}
//分类动画
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.no-data {
  padding-top: 100px;
  text-align: center;
  .nodata-img {
    width: 80%;
  }
  .nodata-text {
    color: #999;
  }
}
.more {
  padding: 28px 0 28px;
  border-top: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #999;
  &::after {
    content: "";
    width: 20px;
    height: 20px;
    background: url(~@/assets/arrows-down-ico.png) no-repeat;
    background-size: 100%;
    margin-left: 8px;
  }
  &.not::after {
    display: none;
  }
}
</style>